import { createStore, combineReducers } from "redux";
//import { applyMiddleware } from "redux";

//import thunkMiddleware from "redux-thunk";
//import { composeWithDevTools } from "redux-devtools-extension";

import { chatReducer } from "./chat/reducers";

const rootReducer = combineReducers({
  chat: chatReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
//  const middlewares = [thunkMiddleware];
//  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
//    composeWithDevTools(middleWareEnhancer)
  );

  return store;
}

